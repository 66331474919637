import styled from "styled-components"

import { containerSideOffset, col } from "../common.styles"

export const NoticesMain = styled.div`
padding: 335px 0 81px;

${props => props.theme.max('mac', () => (`
  padding: 250px 0 81px;
`))}

${props => props.theme.max('md', () => (`
  padding: 210px 0 81px;
`))}

.legal-title {
  margin-bottom: .8em;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 1.6em;
  `))}
}

.legal {
  ${col(14, 'c')}
  margin: 0 auto;

  ${props => props.theme.max('ls', () => (`
    ${col(16, 'c')}
  `))}
  
  ${props => props.theme.max('md', () => (`
    ${containerSideOffset('left')}
    ${col(100)}
  `))}
}

li {
  line-height: 1.63;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -20px;
    border-radius: 50%;
    top: .55em;
    height: .3em;
    width: .3em;
    background-color: currentColor;
  }
}

ul {
  list-style: none;
  margin-top: 0;
}

.ntc-content {
  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    font-family: ${props => props.theme.fonts.primary};
    font-size: 16px;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  table {
    width: 100%;
    margin-bottom: 30px;

    td,
    th {
      padding: 9px 20px 5px;
      text-align: center;
      border-right: 1px solid rgba(20, 28, 37, .2);
      border-bottom: 1px solid rgba(20, 28, 37, .2);

      &:first-child {
        border-left: 1px solid rgba(20, 28, 37, .2);
        text-align: left;
      }
    }
    tr {
      &:first-child {
        th,
        td {
          border-top: 1px solid rgba(20, 28, 37, .2);
        }
      }
    }
  }

}

.anim-ntc-item {
  margin-bottom: 40px;
}

.ntc-item {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.ntc-item-inner {
  >div:last-child p:last-child {
    margin-bottom: 0;
  }
}

.ntc-btn {
  cursor: pointer;
  padding: 4px 0;
  border: none;
  appearance: none;
  border-radius: 0;
  transition: 400ms;
  background: none;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: bold;
  font-size: 12px;

  &:hover {
    opacity: .5;
  }
}
`
