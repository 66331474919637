import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Notices } from "../components/legal/notices-section"

const NoticesTemplate = ({
  data: {
    wpgraphql: {
      page: {
        title,
        noticestemplateacf: {
          noticeList,
        },
      },

    },
  }
}) => {
  return (
    <Layout headerColor={'white'}>
      <SEO title={title} />

      <Notices
        title={title}
        items={noticeList}
      />
    </Layout>
  )
}

export default NoticesTemplate

export const homeTemplateQuery = graphql`
  query NoticesQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        noticestemplateacf {
          noticeList {
            ... on WPGraphQL_Notice {
              noticesacf {
                sections {
                  components {
                    ... on WPGraphQL_Notice_Noticesacf_sections_Components_Text {
                      fieldGroupName
                      text
                    }
                    ... on WPGraphQL_Notice_Noticesacf_sections_Components_Title {
                      fieldGroupName
                      title
                      titleSize
                    }
                    ... on WPGraphQL_Notice_Noticesacf_sections_Components_List {
                      fieldGroupName
                      items {
                        text
                      }
                    }
                    ... on WPGraphQL_Notice_Noticesacf_sections_Components_Table {
                      fieldGroupName
                      tableLabel
                      rows {
                        columns {
                          text
                        }
                      }
                    }
                    ... on WPGraphQL_Notice_Noticesacf_sections_Components_FileList {
                      fieldGroupName
                      items {
                        useStaticFile
                        file {
                          mediaItemUrl
                          mediaItemId
                          modified
                          localStaticUrl
                        }
                        text
                      }
                    }
                    ... on WPGraphQL_Notice_Noticesacf_sections_Components_LinkedContent {
                      email
                      fieldGroupName
                      link
                      linkText
                      perfixText
                      type
                    }
                    ... on WPGraphQL_Notice_Noticesacf_sections_Components_WysiwygText {
                      text
                      fieldGroupName
                    }
                  }
                }
              }
              databaseId
              title
            }
          }
        }
      }
    }
  }
`
