import React from "react"
import AnimateHeight from 'react-animate-height'
import classNames from "classnames"

import { NoticesMain } from "../../styles/legal/notices.styles"

import { FlexComponentW } from "../flex-component-w"

function calculateHeight() {
  return 90;
}

const NtcItem = ({ index, components, title }) => {
  const itemInner = React.useRef(null);

  const [firstItemHeight, setFirstItemHeight] = React.useState(calculateHeight());

  const [pannelStatus, setPanelStatus] = React.useState(calculateHeight());

  function togglePannel() {
    setPanelStatus(pannelStatus === calculateHeight() ? 'auto' : calculateHeight());
  }

  React.useEffect(() => {
    function handleResize() {
      setPanelStatus(pannelStatus === calculateHeight() ? calculateHeight() : 'auto');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (itemInner && itemInner.current) {
      setFirstItemHeight(itemInner.current.getElementsByClassName('first-item').length === 0 ? calculateHeight() + 1 : itemInner.current.getElementsByClassName('ntc-item-inner')[0].offsetHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInner.current]);

  return (
    <div className="ntc-item" ref={itemInner}>
      <h1>{title}</h1>

      <AnimateHeight
        id={`ntc-item-panel-${index}`}
        className="anim-ntc-item"
        duration={500}
        height={pannelStatus} >
        <div className="ntc-item-inner">
          <FlexComponentW components={components} />         
        </div>

      </AnimateHeight>
      {firstItemHeight > calculateHeight() && (
        <button
          aria-expanded={pannelStatus !== calculateHeight}
          aria-controls={`ntc-item-panel-${index}`}
          onClick={togglePannel}
          className={classNames('ntc-btn', pannelStatus === 'auto' ? 'open' : 'closed')}
        >
          {pannelStatus === 'auto' ? 'Show less' : 'Read more'}
        </button>
      )}
    </div>
  )
}

export const Notices = ({ title, items, }) => {


  return (
    <NoticesMain>
      <div className="legal">
        <div>
          <h1 className="legal-title">{title}</h1>
        </div>

        <div className="ntc-content">
          {items.map(({ title, noticesacf: sections }, i) => {
            const components = sections.sections[0].components || [];
            return (
              <NtcItem key={`s-${i}`} title={title} components={components} index={i} />

            )
          })}
        </div>


      </div>
    </NoticesMain >
  )
}
