import React from "react"
import ReactHtmlParser from "react-html-parser"
import classNames from "classnames"

import { WPTextarea, fallbackFile } from "../helpers/common"

import { SectionTextMain } from "../styles/common.styles"

export const FlexComponentW = ({ components }) => {
  return (
    <React.Fragment>
      {components.map((component, i) => {
        switch (component.fieldGroupName) {
          case "notice_Noticesacf_sections_Components_Text":
            return (
              <SectionTextMain key={i} className={classNames({ "first-item": i === 0 })}>
                <WPTextarea text={component?.text} endLink={""} endLinkText={""} />
              </SectionTextMain>
            )

          case "notice_Noticesacf_sections_Components_Title":
            return (
              React.createElement(component.titleSize || 'h1', { key: i }, component.title)
            )

          case "notice_Noticesacf_sections_Components_List":
            const items = component.items || [];
            return (
              <ul key={i} className={classNames({ "first-item": i === 0 })}>
                {items.map(({ text }, i) => (
                  <li key={`listitem${i}`}>
                    {text}
                  </li>
                ))}
              </ul>
            )

          case "notice_Noticesacf_sections_Components_FileList":
            const fileList = component.items;
            return (
              <ul key={i} className={classNames({ "first-item": i === 0 })}>
                {fileList.map(({ text, useStaticFile, file }, i) => (
                  <li key={`listitem${i}`}>
                    <a href={fallbackFile(file?.localStaticUrl, useStaticFile)} target="_blank" rel="noreferrer">{text}</a>
                  </li>
                ))}
              </ul>
            )

          case "notice_Noticesacf_sections_Components_LinkedContent":
            const link = component?.type === 'email' ? `mailto:${component?.email}` : `${component?.link}`;
            const linkType = component?.type === 'link' ? 'external' : component?.type;
            return (
              <SectionTextMain key={i} className={classNames({ "first-item": i === 0 })}>
                <WPTextarea text={component.perfixText} endLink={link || ''} endLinkText={component.linkText || ''} type={linkType} />
              </SectionTextMain>
            )

          case "notice_Noticesacf_sections_Components_WysiwygText":
            return (
              <SectionTextMain key={i} className={classNames({ "first-item": i === 0 })}>
                {ReactHtmlParser(component.text)}
              </SectionTextMain>
            )

          case "notice_Noticesacf_sections_Components_Table":
            return (
              <div key={i} className={classNames({ "first-item": i === 0 })}>
                <p>
                  {component.tableLabel}
                </p>
                <table cellSpacing="0">
                  <tbody>
                    {component.rows.map((col, rowi) => {
                      return (
                        <tr key={`trow-${rowi}`}>
                          {col.columns.map(({ text }, ndx) => {
                            return (
                              React.createElement(rowi === 0 ? 'th' : 'td', { key: `titem-${ndx}` }, text)
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )

          default:
            return (
              <div key={i} className={classNames({ "first-item": i === 0 })}></div>
            )
        }

      })}
    </React.Fragment>
  )
}